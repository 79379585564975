import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Мене звуть <span className="purple">Сушко Андрій. </span>
            Живу в <span className="purple"> Україні, м. Дніпро.</span>
            <br /> Я фронтенд розробник. Займаюсь створенням сайтів та веб додатків
            із використанням стеку MERN (MongoDB, Express.js, React.js, Node.js) та різні бібліотеки.
            <br />
            <br />
            Я є інженером та магістром із технічною вищою освітою. 
            Більшу частину професійного життя займався створенням проєктів в сфері 
            організації свят, заходів, ресторанному обслуговуванні.
            Тому майже всі мої проекти у веб програмуванні пов'язані
            саме з цією доменною сферою.
            <br />
            <br />
            Маю досвід роботи в сфері веб програмування і продовжую
            працювати, одночасно вивчаючи нові технології. Але прагну 
            до професійного розвитку, тому шукаю команду
            із сильною інженерною культурою, в якій міг би проявити
            свої здібності та технічний склад розуму на максимум.
            Буду радий пропозиціям з працевлаштуванням.
            <br />
            <br />
            Своїми сильними сторонами вважаю націленість на результат,
            вміння самостійно організовувати свою роботу і розуміння 
            потреб бізнеса. Ці риси мав можливість напрацювати та 
            проявити в повній мірі під час самостійної підприємницької
            діяльності, яку веду вже впродовж 15 років.
            <br />
            <br />
            Мої хоббі - циклічні види спорта (біг, плавання, тріатлон).
            Найкращим відпочинком вважаю - час проведений з дітьми.
          </p>
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
